/**
Phone button styles
*/

.buttonContainer {
    position: absolute;
    top: 655px;
}

.buttonContainer.next {
    left: 73px;
}

.buttonContainer.select {
    left: 235px;
}

.base {
    background-color: #fff;
    border: none;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    padding: 12px 14px;
    display: block;
}

.base:focus {
    outline: none;
}

.phoneButtonLabel {
    display: block;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    font-weight: 700;
    margin-top: 15px;
    font-size: 20px;
}

:global(.fr) .buttonContainer .phoneButtonLabel {
    position: absolute;
    bottom: -48px;
}

:global(.fr) .buttonContainer.next .phoneButtonLabel {
    left: -4px;
}

:global(.fr) .buttonContainer.select .phoneButtonLabel {
    left: -34px;
}

.base img {
    width: 50px;
    height: auto;
    display: block;
}

.base.next img {
    margin: 0 2px;
    height: 56.125px;
}

.base.select img {
    margin-top: 4px;
    margin-left: 1px;
    height: 45.578px;
}

.base.disabled {
    cursor: default;
}

.buttonContainer.select.centreAnimation {
    left: 235px;
    top: 655px;
    animation-duration: 250ms;
    animation-direction: normal;
    animation-timing-function: ease-out;
    animation-name: phone-button-slide-left;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.buttonContainer.select.centre {
    left: 155px;
    top: 670px;
}

.base.pulse {
    transform: scale(1) translate(0, 0);
    animation-duration: 1.75s;
    animation-direction: normal;
    animation-timing-function: ease;
    animation-name: phone-button-pulse;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-delay: 4.5s;
}

:global(.edge-browser) .base.pulse {
    animation-name: phone-button-pulse-edge;
}

@keyframes phone-button-pulse {
    0% {
        transform: scale(1) translate(0, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }

    30% {
        transform: scale(0.85) translate(0, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);

    }

    35% {
        transform: scale(1.15) translate(0, 0);
    }

    45% {
        transform: scale(1) translate(0, 0);
    }

    100% {
        transform: scale(1) translate(0, 0);
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
}

@keyframes phone-button-pulse-edge {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }

    30% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    100% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
}

@keyframes phone-button-slide-left {
    0% {
        left: 235px;
        top: 655px;
    }
    100% {
        left: 155px;
        top: 670px;
    }
}
