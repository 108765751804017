/**
Styles for the End Game Popup
 */

.egPopup {
    position: absolute;
    width: 775px;
    height: 775px;
    top: 21px;
    left: 57px;
}

.egPopup.inAnimation {
    transform: scale(0);
    transform-origin: center;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-duration: 250ms;
    animation-name: pop-in;
    animation-timing-function: ease-in-out;
    animation-delay: 500ms;
}

.egPopup.outAnimation {
    transform: scale(1);
    transform-origin: center;
    animation-direction: reverse;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-duration: 250ms;
    animation-name: pop-in;
    animation-timing-function: ease-in-out;
}

.egPopup h1 {
    position: relative;
    text-align: center;
    font-weight: 800;
    width: 510px;
    font-size: 52px;
    margin: 230px auto 0 auto;
}

.egPopup h2 {
    position: relative;
    text-align: center;
    width: 560px;
    margin: 30px auto 0 auto;
    font-size: 36px;
    font-weight: bold;
}

.egPopup button.playAgain {
    position: relative;
    text-align: center;
    width: 370px;
    margin: 35px auto 0 auto;
    background-color: #65388b;
    border: none;
    font-weight: 800;
    color: #fff;
    font-size: 2.75rem;
    border-radius: 40px;
    display: block;
    text-transform: uppercase;
}

.egPopup button.playAgain:focus {
    outline: none;
}

.gradientCircle {
    position: absolute;
    top: -20px;
    left: -20px;
    width: 825px;
    height: 825px;
}

:global(.edge-browser) .gradientCircle {
    position: absolute;
    top: -27px;
    left: -32px;
    width: 887px;
    height: 887px;
}

@keyframes pop-in {
    0% {
        transform: scale(0);
    }
    80% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}