/**
Home Screen Bravecto Logo Styles
*/

.bravectoLogoComponent,
.bravectoLogoComponent img {
    position: absolute;
}

.bravectoLogoComponent {
    left: 1215px;
    top: 48px;
    opacity: 1;
}

.bravectoLogoComponent.game {
    left: 120px;
    top: 740px;
}

.bravectoLogoComponent img.bravecto {
    width: 315.679px;
    height: 94.88px;
}

:global(.fr) .bravectoLogoComponent img.bravecto {
    width: 315.679px;
    height: 95.36px;
}

.bravectoLogoComponent img.protection {
    width: auto;
    left: 342.873px;
}

.bravectoLogoComponent img.dogs12Week {
    height: 32.34px;
    top: 1.7169px;
}

.bravectoLogoComponent img.cats12Week {
    height: 36.6px;
    top: 54.643px;
}

.bravectoLogoComponent.game img.protection {
    height: 30px;
    top: 30px;
}

.bravectoLogoComponent.game:global(.dog) img.protection {
    width: 108.34px;
}

.bravectoLogoComponent.game:global(.cat) img.protection {
    width: 107.94px;
}

:global(.fr) .bravectoLogoComponent.game:global(.dog) img.protection {
    width: 115.44px;
}

:global(.fr) .bravectoLogoComponent.game:global(.cat) img.protection {
    width: 114.63px;
}

.bravectoLogoComponent.fadeIn {
    opacity: 0;
    animation-name: bravectoFadeIn;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-delay: 250ms;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-play-state: running;
}

.bravectoLogoComponent.fadeOut {
    animation-duration: 750ms;
    animation-direction: normal;
    animation-timing-function: ease-out;
    animation-name: bravecto-fade-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.bravectoLogoComponent.slideIn {
    animation-duration: 300ms;
    animation-direction: normal;
    animation-timing-function: ease-out;
    animation-name: bravecto-slide-in;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes bravectoFadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes bravecto-fade-out {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes bravecto-slide-in {
    0% {
        left: -1433px;
    }

    100% {
        left: 120px;
    }
}
