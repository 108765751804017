/**
Phone Styles
*/

.phoneComponent {
    position: absolute;
    background-image: url("../../images/svg/PhoneShell.svg");
    background-position: top left;
    background-repeat: no-repeat;
    width: 392.62px;
    height: 800px;
    top: 35px;
    left: 155px;
}

.phoneComponent.game {
    transform: scale(0.68) rotate(-15deg) translate(-111px, -292px);
}

.profiles {
    position: absolute;
    width: 364px;
    height: 535px;
    left: 20px;
    top: 110px;
    overflow: hidden;
}

.backgroundStack,
.profileStack,
.backgroundStack img,
.profileCard {
    position: absolute;
}

.backgroundStack {
    width: 335px;
    left: 18px;
    top: 10px;
}

.backgroundStack img.cardOne {
    transform: rotate(1deg);
    top: 24px;
    left: -5px;
}

.backgroundStack img.cardTwo {
    transform: rotate(-1deg);
    top: 10px;
    left: -4px;
}

.profileCard {
    width: 330px;
    height: 499.615px;
    background-color: #fff;
    border-radius: 7px 7px 22px 22px;
    left: 13px;
    top: 3px;
    overflow: hidden;
    -webkit-box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.5);
    -moz-box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.5);
    box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.5);
}

.profileCard img {
    width: 100%;
    height: auto;
}

.profileCard div {
    padding: 0 1rem;
    color: #41535d;
}

.profileCard div h2,
.profileCard div h4 {
    margin-bottom: 4px;
}

.profileCard div h2 {
    font-weight: 700;
    font-size: 35px;
}

.profileCard div h4 {
    font-weight: 600;
    font-size: 21.5px;
}

.profileCard div h4 pre {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    overflow: visible;
    width: 100%;
}

.profileCardSlideLeft {
    animation-duration: 500ms;
    animation-direction: normal;
    animation-timing-function: ease-out;
    animation-name: slide-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.phoneComponent.slideIn {
     transform: translateX(-555px);
     animation-duration: 250ms;
     animation-direction: normal;
     animation-timing-function: ease-out;
     animation-name: phone-slide-in;
     animation-iteration-count: 1;
     animation-fill-mode: forwards;
     animation-delay: 1s;
 }

.phoneComponent.goToGame {
    animation-duration: 500ms;
    animation-direction: normal;
    animation-timing-function: ease;
    animation-name: phone-go-to-game;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes phone-slide-in {
    0% {
        transform: translateX(-555px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-350px); }
}

@keyframes phone-go-to-game {
    0% {
        transform: scale(1) rotate(0deg) translate(0, 0);
    }
    100% {
        transform: scale(0.68) rotate(-15deg) translate(-111px, -292px);
    }
}
