/**
Screen styles
*/

.gameScreen,
.gameScreenBg {
    position: absolute;
    top: 0;
    left: 0;
}

.gameScreen {
    bottom: 0;
    right: 0;
}

.gameScreenBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 670px;
    height: 900px;
}

.gameScreenBg.slideIn {
    transform: translateX(-680px);
    animation-duration: 150ms;
    animation-direction: normal;
    animation-timing-function: ease-out;
    animation-name: bg-slide-in;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}

.gameScreen.fadeOut {
    opacity: 1;
    animation-duration: 1s;
    animation-direction: normal;
    animation-timing-function: ease;
    animation-name: game-screen-fade-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    /*animation-delay: 2s;*/
}

.gameScreen .homeButton {
    position: absolute;
    bottom: 64px;
    left: 120px;
    width: 320px;
    height: 96px;
    border: none;
    background-color: transparent;
    color: transparent;
}

.gameScreen .homeButton:focus {
    outline: none;
}

@keyframes bg-slide-in {
    0% {
        transform: translateX(-680px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes game-screen-fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
