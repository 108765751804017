/**
Feature Product Circle
 */

.featureProductCircle {
    position: absolute;
    width: 391px;
    height: 391px;
    top: 395px;
    left: 490px;
}

.gradientCircle {
    position: absolute;
    top: -2.5575%;
    left: -2.5575%;
    width: 107%;
}

:global(.edge-browser) .gradientCircle {
    position: absolute;
    top: -2.5575%;
    left: -3.25%;
    width: 114%;
}

.featureProductCircle img.product {
    position: absolute;
    top: -10px;
    left: -5px;
    width: 100%;
    transform-origin: 50%;
    transform: scale(0.55);
}

.featureProductCircle img.product.cat {
    height: 110%;
    width: auto;
    top: -15px;
    left: 85px;
}

.featureProductCircle.inAnimation {
    top: 915px;
    animation-duration: 200ms;
    animation-direction: normal;
    animation-timing-function: ease-in;
    animation-name: product-slide-in;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 500ms;
}

.featureProductCircle.outAnimation {
    animation-duration: 200ms;
    animation-direction: normal;
    animation-timing-function: ease-in;
    animation-name: product-slide-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes product-slide-in {
    0% {
        top: 915px;
    }
    100% {
        top: 395px;
    }
}

@keyframes product-slide-out {
    0% {
        top: 395px;
    }
    100% {
        top: 915px;
    }
}
