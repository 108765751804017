/**
Content Styles
 */

.contentArea,
.contentArea .introText,
.contentArea .playText,
.contentArea .playText .playButton{
    position: absolute;
}

.contentArea {
    left: 680px;
    top: 240px;
    height: 10px;
    width: 750px;
}

.contentArea .introText {
    text-align: center;
}

.contentArea .playText {
    left: 335px;
    text-align: center;
}

:global(.fr) .contentArea .playText {
    left: 250px;
    text-align: center;
}

.contentArea .introText h1,
.contentArea .playText h1 {
    color: #d5283b;
    font-weight: 800;
    font-size: 6.25rem;
    line-height: 1em;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

:global(.fr) .playText h1 {
    margin-top: 2rem !important;
    font-size: 6.75rem;
}

.contentArea .introText h3 {
    color: #65388b;
    font-weight: 600;
    font-size: 3.125rem;
    line-height: 1.4em;
    margin-top: 0.75em;
}

.contentArea .introText h3 strong {
    font-weight: 700;
}

.contentArea .playText .playButton {
    background-color: #65388b;
    border: none;
    font-weight: 800;
    color: #fff;
    top: 300px;
    width: 405px;
    left: 11px;
    font-size: 3.25rem;
    border-radius: 40px;
}

:global(.fr) .contentArea .playText .playButton {
    font-size: 2.65rem;
    left: 24px;
    padding: 10px 20px;
    width: 445px;
}

.contentArea .playText .playButton:focus {
    outline: none;
}

.contentArea .playText.inAnimation h1,
.contentArea .playText.outAnimation h1,
.contentArea .introText.inAnimation h1 {
    animation-duration: 250ms;
    animation-direction: normal;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.contentArea .introText.inAnimation h1 {
    transform: translateX(950px);
    animation-name: h1-slide-in;
    animation-delay: 1s;
}

.contentArea .playText.inAnimation h1 {
    transform: translateX(640px);
    animation-name: play-h1-slide-in;
    animation-delay: 500ms;
}

.contentArea .playText.outAnimation h1 {
    animation-duration: 150ms;
    animation-name: play-h1-slide-out;
}

.contentArea .playText.inAnimation .playButton {
    opacity: 0;
    animation-duration: 1s;
    animation-direction: normal;
    animation-timing-function: ease-in;
    animation-name: play-button-fade-in;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}

.contentArea .playText.outAnimation .playButton {
    animation-duration: 500ms;
    animation-direction: normal;
    animation-timing-function: ease-in;
    animation-name: play-button-fade-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.contentArea .introText h3 span {
    display: inline-block;
}

.contentArea .introText.inAnimation h3 span {
    transform: scale(0);
    transform-origin: center;
    animation-duration: 150ms;
    animation-direction: normal;
    animation-timing-function: ease-out;
    animation-name: span-zoom;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.contentArea .introText.inAnimation h3 span:first-child {
    transform: scale(0);
    animation-delay: 1.5s;
}

.contentArea .introText.inAnimation h3 span:nth-child(2) {
    transform: scale(0);
    animation-delay: 1650ms;
}

.contentArea .introText.inAnimation h3 span:nth-child(3) {
    transform: scale(0);
    animation-delay: 1800ms;
}

.contentArea .introText.inAnimation h3 span:nth-child(4) {
    transform: scale(0);
    animation-delay: 1950ms;
}

.contentArea .introText.inAnimation h3 span:nth-child(5) {
    transform: scale(0);
    animation-delay: 2100ms;
}

.contentArea .introText.inAnimation h3 span:last-child {
    transform: scale(0);
    animation-delay: 2700ms;
}

.contentArea .introText.outAnimation h1 {
    transform: translateX(0);
    animation-duration: 350ms;
    animation-direction: normal;
    animation-timing-function: ease-in;
    animation-name: h1-slide-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.contentArea .introText.outAnimation h3 span {
    transform: scale(1);
    transform-origin: center;
    animation-duration: 150ms;
    animation-direction: normal;
    animation-timing-function: ease-in;
    animation-name: h3-zoom;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.contentArea .introText.outAnimation h3 span:first-child {
    animation-delay: 0s;
}

.contentArea .introText.outAnimation h3 span:nth-child(2) {
    animation-delay: 75ms;
}

.contentArea .introText.outAnimation h3 span:nth-child(3) {
    animation-delay: 150ms;
}

.contentArea .introText.outAnimation h3 span:nth-child(4) {
    animation-delay: 225ms;
}

.contentArea .introText.outAnimation h3 span:nth-child(5) {
    animation-delay: 300ms;
}

.contentArea .introText.outAnimation h3 span:last-child {
    animation-delay: 375ms;
}

@keyframes h1-slide-in {
    0% {
        transform: translateX(950px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes h1-slide-out {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(950px);
    }
}

@keyframes play-h1-slide-in {
    0% {
        transform: translateX(640px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes play-h1-slide-out {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(640px);
    }
}

@keyframes span-zoom {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes h3-zoom {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes play-button-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes play-button-fade-out {
    100% {
        opacity: 0;
    }

    0% {
        opacity: 1;
    }
}