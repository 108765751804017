/**
FinePrint Component Styles
*/

.finePrint {
    position: absolute;
    bottom: 8px;
    left: 100px
}

.finePrint.game {
    opacity: 1;
}

.finePrint.game.inAnimation {
    opacity: 0;
    animation-duration: 400ms;
    animation-direction: normal;
    animation-timing-function: ease-out;
    animation-name: fade-in;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 300ms;
}

.finePrint p.small {
    font-size: 10.5px;
    line-height: 0.85rem;
    color: #41535d;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
