/**
Merck Logo Styles
*/

.merckLogoComponent {
    position: absolute;
    width: 206.823px;
    top: 600px;
    left: 1397px;
}

.merckLogoComponent img.merck {
    width: 100%;
    height: auto;
}

.merckLogoComponent.fadeIn {
    opacity: 0;
    animation-duration: 1s;
    animation-direction: normal;
    animation-timing-function: ease-out;
    animation-name: merck-fade-in;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 500ms;
}

.merckLogoComponent.fadeOut {
    animation-duration: 750ms;
    animation-direction: normal;
    animation-timing-function: ease-out;
    animation-name: merck-fade-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes merck-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes merck-fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
