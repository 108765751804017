/**
Stop Watch Styles
 */

.stopWatch {
    position: absolute;
    left: 375px;
    top: 510px;
    width: 192px;
    height: 192px;
    background-color: #e21c3a;
    border: none;
    border-radius: 96px;
    font-size: 80px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stopWatch.inAnimation {
    transform: scale(0);
    animation-duration: 200ms;
    animation-direction: normal;
    animation-timing-function: ease-out;
    animation-name: pop-in;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 300ms;
}

@keyframes pop-in {
    0% {
        transform: scale(0);
    }
    80% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}
