/**
Styles for the Tip Popup
 */

.tipPopup {
    position: absolute;
    width: 775px;
    height: 775px;
    top: 21px;
    left: 57px;
}

.tipPopup div.card {
    position: absolute;
    width: 160px;
    height: 230px;
    top: 545px;
    left: 641px;
    box-shadow: 5px 5px 15px 0 rgba(0,0,0,0.75);
    border-radius: 33px;
    overflow: hidden;
}

.tipPopup div.card img {
    position: absolute;
    width: 166px;
    height: 236px;
    top: -2px;
    left: -2px;
}

.gradientCircle {
    position: absolute;
    top: -20px;
    left: -20px;
    width: 825px;
    height: 825px;
}

:global(.edge-browser) .gradientCircle {
    position: absolute;
    top: -27px;
    left: -32px;
    width: 887px;
    height: 887px;
}

.tipPopup.inAnimation {
    transform: scale(0);
    transform-origin: center;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-duration: 250ms;
    animation-name: pop-in;
    animation-timing-function: ease-in-out;
}

.tipPopup.outAnimation {
    transform: scale(1);
    transform-origin: center;
    animation-direction: reverse;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-duration: 250ms;
    animation-name: pop-in;
    animation-timing-function: ease-in-out;
    animation-delay: 250ms;
}

.tipPopup.inAnimation div.card {
    transform: scale(0);
    transform-origin: center;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-duration: 250ms;
    animation-name: pop-in;
    animation-timing-function: ease-in-out;
    animation-delay: 250ms;
}

.tipPopup.outAnimation div.card {
    transform: scale(1);
    transform-origin: center;
    animation-direction: reverse;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-duration: 250ms;
    animation-name: pop-in;
    animation-timing-function: ease-in-out;
}

.tipFlipperOuter {
    /*background-color: transparent;*/
    perspective: 1000px;
    margin-top: 103px;
    margin-left: 103px;
    width: 566px;
    height: 566px;
    border-radius: 50%;
}

.tipFlipperInner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.tipFlipperOuter.flipped .tipFlipperInner {
    transform: rotateY(180deg);
}

.tipFlipperFront,
.tipFlipperBack {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

.tipFlipperFront {
    padding: 100px 65px 65px 60px;
}

.tipFlipperBack {
    transform: rotateY(180deg);
    border-radius: 50%;
}

.tipClickerOne {
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    background: transparent;
    color: transparent;
    border-radius: 50%;
    width: 772px;
    height: 772px;
}

.tipClickerTwo {
    position: absolute;
    border: none;
    background: transparent;
    color: transparent;
    width: 158px;
    height: 228px;
    top: 545px;
    left: 642px;
    border-radius: 33px;
}

.tipClickerOne:focus,
.tipClickerTwo:focus {
    outline: none !important;
}

.tipPopup .tipFlipper .tipContent .logo {
    top: 50px;
    left: 105px;
    transform: scale(0.8);
}

:global(.fr) .tipPopup .tipFlipper .tipContent:global(.dog) .logo {
    top: 50px;
    left: 98px;
    transform: scale(0.8);
}

:global(.fr) .tipPopup .tipFlipper .tipContent:global(.cat) .logo {
    top: 50px;
    left: 99px;
    transform: scale(0.8);
}

.tipPopup .tipFlipper .tipContent .logo .protection {
    height: 30px;
    top: 30px;
}

.tipPopup .tipFlipper .tipContent .logo:global(.dog) .protection {
    width: 108.34px;
}

.tipPopup .tipFlipper .tipContent .logo:global(.cat) .protection {
    width: 107.94px;
}

:global(.fr) .tipPopup .tipFlipper .tipContent .logo:global(.dog) .protection {
    width: 115.44px;
}

:global(.fr) .tipPopup .tipFlipper .tipContent .logo:global(.cat) .protection {
    width: 114.63px;
}

.tipBg {
    width: 576px;
    top: 182px;
    left: -6px;
    position: absolute;
}

.tipBg.dog.flea {
    width: 576px;
    top: 182px;
    left: -6px;
}

.tipBg.dog.temperature {
    top: 183px;
}

.tipBg.dog.stethoscope {
    width: 574px;
    top: 183px;
    left: -5px;
}

:global(.fr) .tipBg.dog.stethoscope {
    width: 575px;
    top: 5px;
    left: 7px;
}

.tipBg.dog.tick {
    width: 573px;
    top: 185px;
    left: -5px;
}

.tipBg.dog.chewable {
    width: 150px;
    top: 150px;
    left: 380px;
}

.tipBg.dog.twelveweek {
    width: 180px;
    top: 345px;
    left: 255px;
}

:global(.fr) .tipBg.dog.twelveweek {
    width: 170px;
    top: 355px;
    left: 265px;
}

.tipBg.cat.flea {
    width: 577px;
    top: 183px;
    left: -7px;
}

.tipBg.cat.twelveweek {
    width: 130px;
    top: 305px;
    left: 220px;
}

.tipBg.cat.stethoscope {
    width: 574px;
    top: 183px;
    left: -5px;
}

:global(.fr) .tipBg.cat.stethoscope {
    width: 575px;
    top: 5px;
    left: 7px;
}

.tipBg.cat.tick {
    width: 577px;
    top: 183px;
    left: -7px;
}

.tipBg.cat.catcard {
    width: 580px;
    top: 182px;
    left: -7px;
}

.tipBg.cat.topical {
    width: 150px;
    top: 170px;
    left: 400px;
}

.tipText {
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.5) translate(10px, 10px);
}

.tipText.dog.flea {
    transform: scale(1.5) translateX(8px);
}

:global(.fr) .tipText.dog.flea {
    transform: scale(1.5) translate(0,10px);
}

.tipText.dog.temperature {
    transform: scale(1.5) translateX(8px);
}

:global(.fr) .tipText.dog.temperature {
    transform: scale(1.5) translate(0px, 10px);
}

.tipText.dog.stethoscope {
    transform: scale(1.5) translate(8px, 2px);
}

:global(.fr) .tipText.dog.stethoscope {
    transform: scale(1.5) translate(-10px, 10px);
}

.tipText.dog.tick {
    transform: scale(1.5) translate(8px, 2px);
}

:global(.fr) .tipText.dog.tick {
    transform: scale(1.5) translate(5px, 5px);
}

.tipText.dog.chewable {
    transform: scale(1.5) translateX(9px);
}

:global(.fr) .tipText.dog.chewable {
    transform: scale(1.5) translate(5px, 10px);
}

.tipText.dog.twelveweek {
    transform: scale(1) translate(32px, 158px);
}

:global(.fr) .tipText.dog.twelveweek {
    transform: scale(1.45) translate(-10px, 10px);
}

.tipText.cat.flea {
    transform: scale(1.5) translateX(8px);
}

:global(.fr) .tipText.cat.flea {
    transform: scale(1.45) translate(-15px, 20px);
}

.tipText.cat.twelveweek {
    transform: scale(1.5) translateX(10px);
}

:global(.fr) .tipText.cat.twelveweek {
    transform: scale(1.5) translate(-2px, 10px);
}

.tipText.cat.stethoscope {
    transform: scale(1.5) translate(8px, 2px);
}

:global(.fr) .tipText.cat.stethoscope {
    transform: scale(1.5) translate(-10px, 10px);
}

.tipText.cat.tick {
    transform: scale(1.5) translateX(8px);
}

:global(.fr) .tipText.cat.tick {
    transform: scale(1.5) translate(5px, 10px);
}

.tipText.cat.catcard {
    transform: scale(1.5) translateX(8px);
}

:global(.fr) .tipText.cat.catcard {
    transform: scale(1.5) translate(-5px, 15px);
}

.tipText.cat.topical {
    transform: scale(1.5) translateX(9px);
}

:global(.fr) .tipText.cat.topical {
    transform: scale(1.5) translate(5px, 10px);
}

.disclaimer {
    width: 566px;
    height: 566px;
    font-size: 9px;
    position: absolute;
    left: 0;
}

.disclaimer .textPath {
    /*text-shadow: white 0 0 5px, white 0 0 10px, white 0 0 15px, white 0 0 5px, white 0 0 10px, white 0 0 15px, white 1px 1px 2px;*/
}

.disclaimer.dog.flea {
    transform: scale(0.98) rotate(-42deg);
}

.disclaimer.dog.temperature {
    transform: scale(0.98);
}

.disclaimer.dog.stethoscope {
    transform: scale(0.98) rotate(-42deg);
}

.disclaimer.dog.tick {
    transform: scale(0.98);
}

.disclaimer.dog.chewable {
    transform: scale(0.98) rotate(-60deg);
}

:global(.fr) .disclaimer.dog.chewable {
    transform: scale(0.98) rotate(-49deg);
}

.disclaimer.dog.twelveweek {
    transform: scale(0.98) rotate(-42deg);
}

.disclaimer.cat.flea {
    transform: scale(0.98) rotate(-34.5deg);
}

.disclaimer.cat.twelveweek {
    transform: scale(0.98) rotate(-34.5deg);
}

.disclaimer.cat.stethoscope {
    transform: scale(0.98) rotate(-35deg);
}

.disclaimer.cat.tick {
    transform: scale(0.98);
}

.disclaimer.cat.catcard {
    transform: scale(0.98);
}

.disclaimer.cat.topical {
    transform: scale(0.98) rotate(-60deg);
}

:global(.fr) .disclaimer.cat.topical {
    transform: scale(0.98) rotate(-49deg);
}

@keyframes pop-in {
    0% {
        transform: scale(0);
    }
    80% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}
