/**
Product Heart
 */

.productHeart {
    position: absolute;
    left: 440px;
    top: 347px;
    width: 178px;
    height: 160px;
}

.productHeart.inAnimation {
    opacity: 0;
    transform: scale(0);
    transform-origin: center;
    animation-duration: 300ms;
    animation-direction: normal;
    animation-timing-function: ease-in;
    animation-name: heart-pop-in;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}

.productHeart.outAnimation {
    transform-origin: center;
    animation-duration: 300ms;
    animation-direction: normal;
    animation-timing-function: ease-in;
    animation-name: heart-pop-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes heart-pop-in {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    80% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes heart-pop-out {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(0);
    }
}
