/**
Match Game and Sub-component Styles
 */

.matchGame {
    position: absolute;
    left: 693px;
    top: 39px;
    width: 888px;
    height: 816px;
    display: flex;
    flex-flow: row wrap;
}
.matchGame.waitUntilReady {
    left: -860px;
}

.matchGame.inAnimation {
    left: -860px;
    animation-duration: 300ms;
    animation-direction: normal;
    animation-timing-function: ease-out;
    animation-name: board-slide-in;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.card {
    width: 160px;
    height: 230px;
    margin: 21px 31px;
    background-color: transparent;
    perspective: 1000px;
    padding: 0;
    border: none;
}

.card:focus {
    outline: none;
}

.card.disabled {
    cursor: default !important;
}

.cardInner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.card.faceUp .cardInner {
    transform: rotateY(180deg);
}

.cardFront,
.cardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

.cardBack {
    transform: rotateY(180deg);
}

@keyframes board-slide-in {
    0% {
        left: -860px;
    }
    100% {
        left: 693px;
    }
}
